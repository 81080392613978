export const COUNTRIES = [
  {
    code: '004',
    title: 'АФГАНИСТАН',
    full_title: 'Переходное Исламское Государство Афганистан',
    iso_alpha_2: 'AF',
    iso_alpha_3: 'AFG'
  },
  {
    code: '008',
    title: 'АЛБАНИЯ',
    full_title: 'Республика Албания',
    iso_alpha_2: 'AL',
    iso_alpha_3: 'ALB'
  },
  {
    code: '010',
    title: 'АНТАРКТИДА',
    full_title: '',
    iso_alpha_2: 'AQ',
    iso_alpha_3: 'ATA'
  },
  {
    code: '012',
    title: 'АЛЖИР',
    full_title: 'Алжирская Народная Демократическая Республика',
    iso_alpha_2: 'DZ',
    iso_alpha_3: 'DZA'
  },
  {
    code: '016',
    title: 'АМЕРИКАНСКОЕ САМОА',
    full_title: '',
    iso_alpha_2: 'AS',
    iso_alpha_3: 'ASM'
  },
  {
    code: '020',
    title: 'АНДОРРА',
    full_title: 'Княжество Андорра',
    iso_alpha_2: 'AD',
    iso_alpha_3: 'AND'
  },
  {
    code: '024',
    title: 'АНГОЛА',
    full_title: 'Республика Ангола',
    iso_alpha_2: 'AO',
    iso_alpha_3: 'AGO'
  },
  {
    code: '028',
    title: 'АНТИГУА И БАРБУДА',
    full_title: '',
    iso_alpha_2: 'AG',
    iso_alpha_3: 'ATG'
  },
  {
    code: '031',
    title: 'АЗЕРБАЙДЖАН',
    full_title: 'Республика Азербайджан',
    iso_alpha_2: 'AZ',
    iso_alpha_3: 'AZE'
  },
  {
    code: '032',
    title: 'АРГЕНТИНА',
    full_title: 'Аргентинская Республика',
    iso_alpha_2: 'AR',
    iso_alpha_3: 'ARG'
  },
  {
    code: '036',
    title: 'АВСТРАЛИЯ',
    full_title: '',
    iso_alpha_2: 'AU',
    iso_alpha_3: 'AUS'
  },
  {
    code: '040',
    title: 'АВСТРИЯ',
    full_title: 'Австрийская Республика',
    iso_alpha_2: 'AT',
    iso_alpha_3: 'AUT'
  },
  {
    code: '044',
    title: 'БАГАМЫ',
    full_title: 'Содружество Багамы',
    iso_alpha_2: 'BS',
    iso_alpha_3: 'BHS'
  },
  {
    code: '048',
    title: 'БАХРЕЙН',
    full_title: 'Королевство Бахрейн',
    iso_alpha_2: 'BH',
    iso_alpha_3: 'BHR'
  },
  {
    code: '050',
    title: 'БАНГЛАДЕШ',
    full_title: 'Народная Республика Бангладеш',
    iso_alpha_2: 'BD',
    iso_alpha_3: 'BGD'
  },
  {
    code: '051',
    title: 'АРМЕНИЯ',
    full_title: 'Республика Армения',
    iso_alpha_2: 'AM',
    iso_alpha_3: 'ARM'
  },
  {
    code: '052',
    title: 'БАРБАДОС',
    full_title: '',
    iso_alpha_2: 'BB',
    iso_alpha_3: 'BRB'
  },
  {
    code: '056',
    title: 'БЕЛЬГИЯ',
    full_title: 'Королевство Бельгия',
    iso_alpha_2: 'BE',
    iso_alpha_3: 'BEL'
  },
  {
    code: '060',
    title: 'БЕРМУДЫ',
    full_title: '',
    iso_alpha_2: 'BM',
    iso_alpha_3: 'BMU'
  },
  {
    code: '064',
    title: 'БУТАН',
    full_title: 'Королевство Бутан',
    iso_alpha_2: 'BT',
    iso_alpha_3: 'BTN'
  },
  {
    code: '068',
    title: 'БОЛИВИЯ, МНОГОНАЦИОНАЛЬНОЕ ГОСУДАРСТВО',
    full_title: 'Многонациональное Государство Боливия',
    iso_alpha_2: 'BO',
    iso_alpha_3: 'BOL'
  },
  {
    code: '070',
    title: 'БОСНИЯ И ГЕРЦЕГОВИНА',
    full_title: '',
    iso_alpha_2: 'BA',
    iso_alpha_3: 'BIH'
  },
  {
    code: '072',
    title: 'БОТСВАНА',
    full_title: 'Республика Ботсвана',
    iso_alpha_2: 'BW',
    iso_alpha_3: 'BWA'
  },
  {
    code: '074',
    title: 'ОСТРОВ БУВЕ',
    full_title: '',
    iso_alpha_2: 'BV',
    iso_alpha_3: 'BVT'
  },
  {
    code: '076',
    title: 'БРАЗИЛИЯ',
    full_title: 'Федеративная Республика Бразилия',
    iso_alpha_2: 'BR',
    iso_alpha_3: 'BRA'
  },
  {
    code: '084',
    title: 'БЕЛИЗ',
    full_title: '',
    iso_alpha_2: 'BZ',
    iso_alpha_3: 'BLZ'
  },
  {
    code: '086',
    title: 'БРИТАНСКАЯ ТЕРРИТОРИЯ В ИНДИЙСКОМ ОКЕАНЕ',
    full_title: '',
    iso_alpha_2: 'IO',
    iso_alpha_3: 'IOT'
  },
  {
    code: '090',
    title: 'СОЛОМОНОВЫ ОСТРОВА',
    full_title: '',
    iso_alpha_2: 'SB',
    iso_alpha_3: 'SLB'
  },
  {
    code: '092',
    title: 'ВИРГИНСКИЕ ОСТРОВА (БРИТАНСКИЕ)',
    full_title: 'Британские Виргинские острова',
    iso_alpha_2: 'VG',
    iso_alpha_3: 'VGB'
  },
  {
    code: '096',
    title: 'БРУНЕЙ-ДАРУССАЛАМ',
    full_title: '',
    iso_alpha_2: 'BN',
    iso_alpha_3: 'BRN'
  },
  {
    code: '100',
    title: 'БОЛГАРИЯ',
    full_title: 'Республика Болгария',
    iso_alpha_2: 'BG',
    iso_alpha_3: 'BGR'
  },
  {
    code: '104',
    title: 'МЬЯНМА',
    full_title: 'Республика Союза Мьянма',
    iso_alpha_2: 'MM',
    iso_alpha_3: 'MMR'
  },
  {
    code: '108',
    title: 'БУРУНДИ',
    full_title: 'Республика Бурунди',
    iso_alpha_2: 'BI',
    iso_alpha_3: 'BDI'
  },
  {
    code: '112',
    title: 'БЕЛАРУСЬ',
    full_title: 'Республика Беларусь',
    iso_alpha_2: 'BY',
    iso_alpha_3: 'BLR'
  },
  {
    code: '116',
    title: 'КАМБОДЖА',
    full_title: 'Королевство Камбоджа',
    iso_alpha_2: 'KH',
    iso_alpha_3: 'KHM'
  },
  {
    code: '120',
    title: 'КАМЕРУН',
    full_title: 'Республика Камерун',
    iso_alpha_2: 'CM',
    iso_alpha_3: 'CMR'
  },
  {
    code: '124',
    title: 'КАНАДА',
    full_title: '',
    iso_alpha_2: 'CA',
    iso_alpha_3: 'CAN'
  },
  {
    code: '132',
    title: 'КАБО-ВЕРДЕ',
    full_title: 'Республика Кабо-Верде',
    iso_alpha_2: 'CV',
    iso_alpha_3: 'CPV'
  },
  {
    code: '136',
    title: 'ОСТРОВА КАЙМАН',
    full_title: '',
    iso_alpha_2: 'KY',
    iso_alpha_3: 'CYM'
  },
  {
    code: '140',
    title: 'ЦЕНТРАЛЬНО-АФРИКАНСКАЯ РЕСПУБЛИКА',
    full_title: '',
    iso_alpha_2: 'CF',
    iso_alpha_3: 'CAF'
  },
  {
    code: '144',
    title: 'ШРИ-ЛАНКА',
    full_title: 'Демократическая Социалистическая Республика Шри-Ланка',
    iso_alpha_2: 'LK',
    iso_alpha_3: 'LKA'
  },
  {
    code: '148',
    title: 'ЧАД',
    full_title: 'Республика Чад',
    iso_alpha_2: 'TD',
    iso_alpha_3: 'TCD'
  },
  {
    code: '152',
    title: 'ЧИЛИ',
    full_title: 'Республика Чили',
    iso_alpha_2: 'CL',
    iso_alpha_3: 'CHL'
  },
  {
    code: '156',
    title: 'КИТАЙ',
    full_title: 'Китайская Народная Республика',
    iso_alpha_2: 'CN',
    iso_alpha_3: 'CHN'
  },
  {
    code: '158',
    title: 'ТАЙВАНЬ (КИТАЙ)',
    full_title: '',
    iso_alpha_2: 'TW',
    iso_alpha_3: 'TWN'
  },
  {
    code: '162',
    title: 'ОСТРОВ РОЖДЕСТВА',
    full_title: '',
    iso_alpha_2: 'CX',
    iso_alpha_3: 'CXR'
  },
  {
    code: '166',
    title: 'КОКОСОВЫЕ (КИЛИНГ) ОСТРОВА',
    full_title: '',
    iso_alpha_2: 'CC',
    iso_alpha_3: 'CCK'
  },
  {
    code: '170',
    title: 'КОЛУМБИЯ',
    full_title: 'Республика Колумбия',
    iso_alpha_2: 'CO',
    iso_alpha_3: 'COL'
  },
  {
    code: '174',
    title: 'КОМОРЫ',
    full_title: 'Союз Коморы',
    iso_alpha_2: 'KM',
    iso_alpha_3: 'COM'
  },
  {
    code: '175',
    title: 'МАЙОТТА',
    full_title: '',
    iso_alpha_2: 'YT',
    iso_alpha_3: 'MYT'
  },
  {
    code: '178',
    title: 'КОНГО',
    full_title: 'Республика Конго',
    iso_alpha_2: 'CG',
    iso_alpha_3: 'COG'
  },
  {
    code: '180',
    title: 'КОНГО, ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
    full_title: 'Демократическая Республика Конго',
    iso_alpha_2: 'CD',
    iso_alpha_3: 'COD'
  },
  {
    code: '184',
    title: 'ОСТРОВА КУКА',
    full_title: '',
    iso_alpha_2: 'CK',
    iso_alpha_3: 'COK'
  },
  {
    code: '188',
    title: 'КОСТА-РИКА',
    full_title: 'Республика Коста-Рика',
    iso_alpha_2: 'CR',
    iso_alpha_3: 'CRI'
  },
  {
    code: '191',
    title: 'ХОРВАТИЯ',
    full_title: 'Республика Хорватия',
    iso_alpha_2: 'HR',
    iso_alpha_3: 'HRV'
  },
  {
    code: '192',
    title: 'КУБА',
    full_title: 'Республика Куба',
    iso_alpha_2: 'CU',
    iso_alpha_3: 'CUB'
  },
  {
    code: '196',
    title: 'КИПР',
    full_title: 'Республика Кипр',
    iso_alpha_2: 'CY',
    iso_alpha_3: 'CYP'
  },
  {
    code: '203',
    title: 'ЧЕХИЯ',
    full_title: 'Чешская Республика',
    iso_alpha_2: 'CZ',
    iso_alpha_3: 'CZE'
  },
  {
    code: '204',
    title: 'БЕНИН',
    full_title: 'Республика Бенин',
    iso_alpha_2: 'BJ',
    iso_alpha_3: 'BEN'
  },
  {
    code: '208',
    title: 'ДАНИЯ',
    full_title: 'Королевство Дания',
    iso_alpha_2: 'DK',
    iso_alpha_3: 'DNK'
  },
  {
    code: '212',
    title: 'ДОМИНИКА',
    full_title: 'Содружество Доминики',
    iso_alpha_2: 'DM',
    iso_alpha_3: 'DMA'
  },
  {
    code: '214',
    title: 'ДОМИНИКАНСКАЯ РЕСПУБЛИКА',
    full_title: '',
    iso_alpha_2: 'DO',
    iso_alpha_3: 'DOM'
  },
  {
    code: '218',
    title: 'ЭКВАДОР',
    full_title: 'Республика Эквадор',
    iso_alpha_2: 'EC',
    iso_alpha_3: 'ECU'
  },
  {
    code: '222',
    title: 'ЭЛЬ-САЛЬВАДОР',
    full_title: 'Республика Эль-Сальвадор',
    iso_alpha_2: 'SV',
    iso_alpha_3: 'SLV'
  },
  {
    code: '226',
    title: 'ЭКВАТОРИАЛЬНАЯ ГВИНЕЯ',
    full_title: 'Республика Экваториальная Гвинея',
    iso_alpha_2: 'GQ',
    iso_alpha_3: 'GNQ'
  },
  {
    code: '231',
    title: 'ЭФИОПИЯ',
    full_title: 'Федеративная Демократическая Республика Эфиопия',
    iso_alpha_2: 'ET',
    iso_alpha_3: 'ETH'
  },
  {
    code: '232',
    title: 'ЭРИТРЕЯ',
    full_title: 'Государство Эритрея',
    iso_alpha_2: 'ER',
    iso_alpha_3: 'ERI'
  },
  {
    code: '233',
    title: 'ЭСТОНИЯ',
    full_title: 'Эстонская Республика',
    iso_alpha_2: 'EE',
    iso_alpha_3: 'EST'
  },
  {
    code: '234',
    title: 'ФАРЕРСКИЕ ОСТРОВА',
    full_title: '',
    iso_alpha_2: 'FO',
    iso_alpha_3: 'FRO'
  },
  {
    code: '238',
    title: 'ФОЛКЛЕНДСКИЕ ОСТРОВА (МАЛЬВИНСКИЕ)',
    full_title: '',
    iso_alpha_2: 'FK',
    iso_alpha_3: 'FLK'
  },
  {
    code: '239',
    title: 'ЮЖНАЯ ДЖОРДЖИЯ И ЮЖНЫЕ САНДВИЧЕВЫ ОСТРОВА',
    full_title: '',
    iso_alpha_2: 'GS',
    iso_alpha_3: 'SGS'
  },
  {
    code: '242',
    title: 'ФИДЖИ',
    full_title: 'Республика Фиджи',
    iso_alpha_2: 'FJ',
    iso_alpha_3: 'FJI'
  },
  {
    code: '246',
    title: 'ФИНЛЯНДИЯ',
    full_title: 'Финляндская Республика',
    iso_alpha_2: 'FI',
    iso_alpha_3: 'FIN'
  },
  {
    code: '248',
    title: 'ЭЛАНДСКИЕ ОСТРОВА',
    full_title: '',
    iso_alpha_2: 'АХ',
    iso_alpha_3: 'ALA'
  },
  {
    code: '250',
    title: 'ФРАНЦИЯ',
    full_title: 'Французская Республика',
    iso_alpha_2: 'FR',
    iso_alpha_3: 'FRA'
  },
  {
    code: '254',
    title: 'ФРАНЦУЗСКАЯ ГВИАНА',
    full_title: '',
    iso_alpha_2: 'GF',
    iso_alpha_3: 'GUF'
  },
  {
    code: '258',
    title: 'ФРАНЦУЗСКАЯ ПОЛИНЕЗИЯ',
    full_title: '',
    iso_alpha_2: 'PF',
    iso_alpha_3: 'PYF'
  },
  {
    code: '260',
    title: 'ФРАНЦУЗСКИЕ ЮЖНЫЕ ТЕРРИТОРИИ',
    full_title: '',
    iso_alpha_2: 'TF',
    iso_alpha_3: 'ATF'
  },
  {
    code: '262',
    title: 'ДЖИБУТИ',
    full_title: 'Республика Джибути',
    iso_alpha_2: 'DJ',
    iso_alpha_3: 'DJI'
  },
  {
    code: '266',
    title: 'ГАБОН',
    full_title: 'Габонская Республика',
    iso_alpha_2: 'GA',
    iso_alpha_3: 'GAB'
  },
  {
    code: '268',
    title: 'ГРУЗИЯ',
    full_title: '',
    iso_alpha_2: 'GE',
    iso_alpha_3: 'GEO'
  },
  {
    code: '270',
    title: 'ГАМБИЯ',
    full_title: 'Республика Гамбия',
    iso_alpha_2: 'GM',
    iso_alpha_3: 'GMB'
  },
  {
    code: '275',
    title: 'ПАЛЕСТИНА, ГОСУДАРСТВО',
    full_title: 'Государство Палестина',
    iso_alpha_2: 'PS',
    iso_alpha_3: 'PSE'
  },
  {
    code: '276',
    title: 'ГЕРМАНИЯ',
    full_title: 'Федеративная Республика Германия',
    iso_alpha_2: 'DE',
    iso_alpha_3: 'DEU'
  },
  {
    code: '288',
    title: 'ГАНА',
    full_title: 'Республика Гана',
    iso_alpha_2: 'GH',
    iso_alpha_3: 'GHA'
  },
  {
    code: '292',
    title: 'ГИБРАЛТАР',
    full_title: '',
    iso_alpha_2: 'GI',
    iso_alpha_3: 'GIB'
  },
  {
    code: '296',
    title: 'КИРИБАТИ',
    full_title: 'Республика Кирибати',
    iso_alpha_2: 'KI',
    iso_alpha_3: 'KIR'
  },
  {
    code: '300',
    title: 'ГРЕЦИЯ',
    full_title: 'Греческая Республика',
    iso_alpha_2: 'GR',
    iso_alpha_3: 'GRC'
  },
  {
    code: '304',
    title: 'ГРЕНЛАНДИЯ',
    full_title: '',
    iso_alpha_2: 'GL',
    iso_alpha_3: 'GRL'
  },
  {
    code: '308',
    title: 'ГРЕНАДА',
    full_title: '',
    iso_alpha_2: 'GD',
    iso_alpha_3: 'GRD'
  },
  {
    code: '312',
    title: 'ГВАДЕЛУПА',
    full_title: '',
    iso_alpha_2: 'GP',
    iso_alpha_3: 'GLP'
  },
  {
    code: '316',
    title: 'ГУАМ',
    full_title: '',
    iso_alpha_2: 'GU',
    iso_alpha_3: 'GUM'
  },
  {
    code: '320',
    title: 'ГВАТЕМАЛА',
    full_title: 'Республика Гватемала',
    iso_alpha_2: 'GT',
    iso_alpha_3: 'GTM'
  },
  {
    code: '324',
    title: 'ГВИНЕЯ',
    full_title: 'Гвинейская Республика',
    iso_alpha_2: 'GN',
    iso_alpha_3: 'GIN'
  },
  {
    code: '328',
    title: 'ГАЙАНА',
    full_title: 'Республика Гайана',
    iso_alpha_2: 'GY',
    iso_alpha_3: 'GUY'
  },
  {
    code: '332',
    title: 'ГАИТИ',
    full_title: 'Республика Гаити',
    iso_alpha_2: 'HT',
    iso_alpha_3: 'HTI'
  },
  {
    code: '334',
    title: 'ОСТРОВ ХЕРД И ОСТРОВА МАКДОНАЛЬД',
    full_title: '',
    iso_alpha_2: 'HM',
    iso_alpha_3: 'HMD'
  },
  {
    code: '336',
    title: 'ПАПСКИЙ ПРЕСТОЛ (ГОСУДАРСТВО - ГОРОД ВАТИКАН)',
    full_title: '',
    iso_alpha_2: 'VA',
    iso_alpha_3: 'VAT'
  },
  {
    code: '340',
    title: 'ГОНДУРАС',
    full_title: 'Республика Гондурас',
    iso_alpha_2: 'HN',
    iso_alpha_3: 'HND'
  },
  {
    code: '344',
    title: 'ГОНКОНГ',
    full_title: 'Специальный административный регион Китая Гонконг',
    iso_alpha_2: 'HK',
    iso_alpha_3: 'HKG'
  },
  {
    code: '348',
    title: 'ВЕНГРИЯ',
    full_title: 'Венгрия',
    iso_alpha_2: 'HU',
    iso_alpha_3: 'HUN'
  },
  {
    code: '352',
    title: 'ИСЛАНДИЯ',
    full_title: 'Республика Исландия',
    iso_alpha_2: 'IS',
    iso_alpha_3: 'ISL'
  },
  {
    code: '356',
    title: 'ИНДИЯ',
    full_title: 'Республика Индия',
    iso_alpha_2: 'IN',
    iso_alpha_3: 'IND'
  },
  {
    code: '360',
    title: 'ИНДОНЕЗИЯ',
    full_title: 'Республика Индонезия',
    iso_alpha_2: 'ID',
    iso_alpha_3: 'IDN'
  },
  {
    code: '364',
    title: 'ИРАН (ИСЛАМСКАЯ РЕСПУБЛИКА)',
    full_title: 'Исламская Республика Иран',
    iso_alpha_2: 'IR',
    iso_alpha_3: 'IRN'
  },
  {
    code: '368',
    title: 'ИРАК',
    full_title: 'Республика Ирак',
    iso_alpha_2: 'IQ',
    iso_alpha_3: 'IRQ'
  },
  {
    code: '372',
    title: 'ИРЛАНДИЯ',
    full_title: '',
    iso_alpha_2: 'IE',
    iso_alpha_3: 'IRL'
  },
  {
    code: '376',
    title: 'ИЗРАИЛЬ',
    full_title: 'Государство Израиль',
    iso_alpha_2: 'IL',
    iso_alpha_3: 'ISR'
  },
  {
    code: '380',
    title: 'ИТАЛИЯ',
    full_title: 'Итальянская Республика',
    iso_alpha_2: 'IT',
    iso_alpha_3: 'ITA'
  },
  {
    code: '384',
    title: "КОТ Д'ИВУАР",
    full_title: "Республика Кот д'Ивуар",
    iso_alpha_2: 'CI',
    iso_alpha_3: 'CIV'
  },
  {
    code: '388',
    title: 'ЯМАЙКА',
    full_title: '',
    iso_alpha_2: 'JM',
    iso_alpha_3: 'JAM'
  },
  {
    code: '392',
    title: 'ЯПОНИЯ',
    full_title: '',
    iso_alpha_2: 'JP',
    iso_alpha_3: 'JPN'
  },
  {
    code: '398',
    title: 'КАЗАХСТАН',
    full_title: 'Республика Казахстан',
    iso_alpha_2: 'KZ',
    iso_alpha_3: 'KAZ'
  },
  {
    code: '400',
    title: 'ИОРДАНИЯ',
    full_title: 'Иорданское Хашимитское Королевство',
    iso_alpha_2: 'JO',
    iso_alpha_3: 'JOR'
  },
  {
    code: '404',
    title: 'КЕНИЯ',
    full_title: 'Республика Кения',
    iso_alpha_2: 'KE',
    iso_alpha_3: 'KEN'
  },
  {
    code: '408',
    title: 'КОРЕЯ, НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
    full_title: 'Корейская Народно-Демократическая Республика',
    iso_alpha_2: 'KP',
    iso_alpha_3: 'PRK'
  },
  {
    code: '410',
    title: 'КОРЕЯ, РЕСПУБЛИКА',
    full_title: 'Республика Корея',
    iso_alpha_2: 'KR',
    iso_alpha_3: 'KOR'
  },
  {
    code: '414',
    title: 'КУВЕЙТ',
    full_title: 'Государство Кувейт',
    iso_alpha_2: 'KW',
    iso_alpha_3: 'KWT'
  },
  {
    code: '417',
    title: 'КИРГИЗИЯ',
    full_title: 'Киргизская Республика',
    iso_alpha_2: 'KG',
    iso_alpha_3: 'KGZ'
  },
  {
    code: '418',
    title: 'ЛАОССКАЯ НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
    full_title: '',
    iso_alpha_2: 'LA',
    iso_alpha_3: 'LAO'
  },
  {
    code: '422',
    title: 'ЛИВАН',
    full_title: 'Ливанская Республика',
    iso_alpha_2: 'LB',
    iso_alpha_3: 'LBN'
  },
  {
    code: '426',
    title: 'ЛЕСОТО',
    full_title: 'Королевство Лесото',
    iso_alpha_2: 'LS',
    iso_alpha_3: 'LSO'
  },
  {
    code: '428',
    title: 'ЛАТВИЯ',
    full_title: 'Латвийская Республика',
    iso_alpha_2: 'LV',
    iso_alpha_3: 'LVA'
  },
  {
    code: '430',
    title: 'ЛИБЕРИЯ',
    full_title: 'Республика Либерия',
    iso_alpha_2: 'LR',
    iso_alpha_3: 'LBR'
  },
  {
    code: '434',
    title: 'ЛИВИЯ',
    full_title: 'Государство Ливия',
    iso_alpha_2: 'LY',
    iso_alpha_3: 'LBY'
  },
  {
    code: '438',
    title: 'ЛИХТЕНШТЕЙН',
    full_title: 'Княжество Лихтенштейн',
    iso_alpha_2: 'LI',
    iso_alpha_3: 'LIE'
  },
  {
    code: '440',
    title: 'ЛИТВА',
    full_title: 'Литовская Республика',
    iso_alpha_2: 'LT',
    iso_alpha_3: 'LTU'
  },
  {
    code: '442',
    title: 'ЛЮКСЕМБУРГ',
    full_title: 'Великое Герцогство Люксембург',
    iso_alpha_2: 'LU',
    iso_alpha_3: 'LUX'
  },
  {
    code: '446',
    title: 'МАКАО',
    full_title: 'Специальный административный регион Китая Макао',
    iso_alpha_2: 'MO',
    iso_alpha_3: 'MAC'
  },
  {
    code: '450',
    title: 'МАДАГАСКАР',
    full_title: 'Республика Мадагаскар',
    iso_alpha_2: 'MG',
    iso_alpha_3: 'MDG'
  },
  {
    code: '454',
    title: 'МАЛАВИ',
    full_title: 'Республика Малави',
    iso_alpha_2: 'MW',
    iso_alpha_3: 'MWI'
  },
  {
    code: '458',
    title: 'МАЛАЙЗИЯ',
    full_title: '',
    iso_alpha_2: 'MY',
    iso_alpha_3: 'MYS'
  },
  {
    code: '462',
    title: 'МАЛЬДИВЫ',
    full_title: 'Мальдивская Республика',
    iso_alpha_2: 'MV',
    iso_alpha_3: 'MDV'
  },
  {
    code: '466',
    title: 'МАЛИ',
    full_title: 'Республика Мали',
    iso_alpha_2: 'ML',
    iso_alpha_3: 'MLI'
  },
  {
    code: '470',
    title: 'МАЛЬТА',
    full_title: 'Республика Мальта',
    iso_alpha_2: 'MT',
    iso_alpha_3: 'MLT'
  },
  {
    code: '474',
    title: 'МАРТИНИКА',
    full_title: '',
    iso_alpha_2: 'MQ',
    iso_alpha_3: 'MTQ'
  },
  {
    code: '478',
    title: 'МАВРИТАНИЯ',
    full_title: 'Исламская Республика Мавритания',
    iso_alpha_2: 'MR',
    iso_alpha_3: 'MRT'
  },
  {
    code: '480',
    title: 'МАВРИКИЙ',
    full_title: 'Республика Маврикий',
    iso_alpha_2: 'MU',
    iso_alpha_3: 'MUS'
  },
  {
    code: '484',
    title: 'МЕКСИКА',
    full_title: 'Мексиканские Соединенные Штаты',
    iso_alpha_2: 'MX',
    iso_alpha_3: 'MEX'
  },
  {
    code: '492',
    title: 'МОНАКО',
    full_title: 'Княжество Монако',
    iso_alpha_2: 'MC',
    iso_alpha_3: 'MCO'
  },
  {
    code: '496',
    title: 'МОНГОЛИЯ',
    full_title: '',
    iso_alpha_2: 'MN',
    iso_alpha_3: 'MNG'
  },
  {
    code: '498',
    title: 'МОЛДОВА, РЕСПУБЛИКА',
    full_title: 'Республика Молдова',
    iso_alpha_2: 'MD',
    iso_alpha_3: 'MDA'
  },
  {
    code: '499',
    title: 'ЧЕРНОГОРИЯ',
    full_title: '',
    iso_alpha_2: 'ME',
    iso_alpha_3: 'MNE'
  },
  {
    code: '500',
    title: 'МОНТСЕРРАТ',
    full_title: '',
    iso_alpha_2: 'MS',
    iso_alpha_3: 'MSR'
  },
  {
    code: '504',
    title: 'МАРОККО',
    full_title: 'Королевство Марокко',
    iso_alpha_2: 'MA',
    iso_alpha_3: 'MAR'
  },
  {
    code: '508',
    title: 'МОЗАМБИК',
    full_title: 'Республика Мозамбик',
    iso_alpha_2: 'MZ',
    iso_alpha_3: 'MOZ'
  },
  {
    code: '512',
    title: 'ОМАН',
    full_title: 'Султанат Оман',
    iso_alpha_2: 'OM',
    iso_alpha_3: 'OMN'
  },
  {
    code: '516',
    title: 'НАМИБИЯ',
    full_title: 'Республика Намибия',
    iso_alpha_2: 'NA',
    iso_alpha_3: 'NAM'
  },
  {
    code: '520',
    title: 'НАУРУ',
    full_title: 'Республика Науру',
    iso_alpha_2: 'NR',
    iso_alpha_3: 'NRU'
  },
  {
    code: '524',
    title: 'НЕПАЛ',
    full_title: 'Федеративная Демократическая Республика Непал',
    iso_alpha_2: 'NP',
    iso_alpha_3: 'NPL'
  },
  {
    code: '528',
    title: 'НИДЕРЛАНДЫ',
    full_title: 'Королевство Нидерландов',
    iso_alpha_2: 'NL',
    iso_alpha_3: 'NLD'
  },
  {
    code: '531',
    title: 'КЮРАСАО',
    full_title: '',
    iso_alpha_2: 'CW',
    iso_alpha_3: 'CUW'
  },
  {
    code: '533',
    title: 'АРУБА',
    full_title: '',
    iso_alpha_2: 'AW',
    iso_alpha_3: 'ABW'
  },
  {
    code: '534',
    title: 'СЕН-МАРТЕН (нидерландская часть)',
    full_title: '',
    iso_alpha_2: 'SX',
    iso_alpha_3: 'SXM'
  },
  {
    code: '535',
    title: 'БОНЭЙР, СИНТ-ЭСТАТИУС И САБА',
    full_title: '',
    iso_alpha_2: 'BQ',
    iso_alpha_3: 'BES'
  },
  {
    code: '540',
    title: 'НОВАЯ КАЛЕДОНИЯ',
    full_title: '',
    iso_alpha_2: 'NC',
    iso_alpha_3: 'NCL'
  },
  {
    code: '548',
    title: 'ВАНУАТУ',
    full_title: 'Республика Вануату',
    iso_alpha_2: 'VU',
    iso_alpha_3: 'VUT'
  },
  {
    code: '554',
    title: 'НОВАЯ ЗЕЛАНДИЯ',
    full_title: '',
    iso_alpha_2: 'NZ',
    iso_alpha_3: 'NZL'
  },
  {
    code: '558',
    title: 'НИКАРАГУА',
    full_title: 'Республика Никарагуа',
    iso_alpha_2: 'NI',
    iso_alpha_3: 'NIC'
  },
  {
    code: '562',
    title: 'НИГЕР',
    full_title: 'Республика Нигер',
    iso_alpha_2: 'NE',
    iso_alpha_3: 'NER'
  },
  {
    code: '566',
    title: 'НИГЕРИЯ',
    full_title: 'Федеративная Республика Нигерия',
    iso_alpha_2: 'NG',
    iso_alpha_3: 'NGA'
  },
  {
    code: '570',
    title: 'НИУЭ',
    full_title: 'Республика Ниуэ',
    iso_alpha_2: 'NU',
    iso_alpha_3: 'NIU'
  },
  {
    code: '574',
    title: 'ОСТРОВ НОРФОЛК',
    full_title: '',
    iso_alpha_2: 'NF',
    iso_alpha_3: 'NFK'
  },
  {
    code: '578',
    title: 'НОРВЕГИЯ',
    full_title: 'Королевство Норвегия',
    iso_alpha_2: 'NO',
    iso_alpha_3: 'NOR'
  },
  {
    code: '580',
    title: 'СЕВЕРНЫЕ МАРИАНСКИЕ ОСТРОВА',
    full_title: 'Содружество Северных Марианских островов',
    iso_alpha_2: 'MP',
    iso_alpha_3: 'MNP'
  },
  {
    code: '581',
    title: 'МАЛЫЕ ТИХООКЕАНСКИЕ ОТДАЛЕННЫЕ ОСТРОВА СОЕДИНЕННЫХ ШТАТОВ',
    full_title: '',
    iso_alpha_2: 'UM',
    iso_alpha_3: 'UMI'
  },
  {
    code: '583',
    title: 'МИКРОНЕЗИЯ, ФЕДЕРАТИВНЫЕ ШТАТЫ',
    full_title: 'Федеративные штаты Микронезии',
    iso_alpha_2: 'FM',
    iso_alpha_3: 'FSM'
  },
  {
    code: '584',
    title: 'МАРШАЛЛОВЫ ОСТРОВА',
    full_title: 'Республика Маршалловы Острова',
    iso_alpha_2: 'MH',
    iso_alpha_3: 'MHL'
  },
  {
    code: '585',
    title: 'ПАЛАУ',
    full_title: 'Республика Палау',
    iso_alpha_2: 'PW',
    iso_alpha_3: 'PLW'
  },
  {
    code: '586',
    title: 'ПАКИСТАН',
    full_title: 'Исламская Республика Пакистан',
    iso_alpha_2: 'PK',
    iso_alpha_3: 'PAK'
  },
  {
    code: '591',
    title: 'ПАНАМА',
    full_title: 'Республика Панама',
    iso_alpha_2: 'PA',
    iso_alpha_3: 'PAN'
  },
  {
    code: '598',
    title: 'ПАПУА-НОВАЯ ГВИНЕЯ',
    full_title: 'Независимое Государство  Папуа Новая Гвинея',
    iso_alpha_2: 'PG',
    iso_alpha_3: 'PNG'
  },
  {
    code: '600',
    title: 'ПАРАГВАЙ',
    full_title: 'Республика Парагвай',
    iso_alpha_2: 'PY',
    iso_alpha_3: 'PRY'
  },
  {
    code: '604',
    title: 'ПЕРУ',
    full_title: 'Республика Перу',
    iso_alpha_2: 'PE',
    iso_alpha_3: 'PER'
  },
  {
    code: '608',
    title: 'ФИЛИППИНЫ',
    full_title: 'Республика Филиппины',
    iso_alpha_2: 'PH',
    iso_alpha_3: 'PHL'
  },
  {
    code: '612',
    title: 'ПИТКЕРН',
    full_title: '',
    iso_alpha_2: 'PN',
    iso_alpha_3: 'PCN'
  },
  {
    code: '616',
    title: 'ПОЛЬША',
    full_title: 'Республика Польша',
    iso_alpha_2: 'PL',
    iso_alpha_3: 'POL'
  },
  {
    code: '620',
    title: 'ПОРТУГАЛИЯ',
    full_title: 'Португальская Республика',
    iso_alpha_2: 'PT',
    iso_alpha_3: 'PRT'
  },
  {
    code: '624',
    title: 'ГВИНЕЯ-БИСАУ',
    full_title: 'Республика Гвинея-Бисау',
    iso_alpha_2: 'GW',
    iso_alpha_3: 'GNB'
  },
  {
    code: '626',
    title: 'ТИМОР-ЛЕСТЕ',
    full_title: 'Демократическая Республика Тимор-Лесте',
    iso_alpha_2: 'TL',
    iso_alpha_3: 'TLS'
  },
  {
    code: '630',
    title: 'ПУЭРТО-РИКО',
    full_title: '',
    iso_alpha_2: 'PR',
    iso_alpha_3: 'PRI'
  },
  {
    code: '634',
    title: 'КАТАР',
    full_title: 'Государство Катар',
    iso_alpha_2: 'QA',
    iso_alpha_3: 'QAT'
  },
  {
    code: '638',
    title: 'РЕЮНЬОН',
    full_title: '',
    iso_alpha_2: 'RE',
    iso_alpha_3: 'REU'
  },
  {
    code: '642',
    title: 'РУМЫНИЯ',
    full_title: '',
    iso_alpha_2: 'RO',
    iso_alpha_3: 'ROU'
  },
  {
    code: '643',
    title: 'РОССИЯ',
    full_title: 'Российская Федерация',
    iso_alpha_2: 'RU',
    iso_alpha_3: 'RUS'
  },
  {
    code: '646',
    title: 'РУАНДА',
    full_title: 'Руандийская Республика',
    iso_alpha_2: 'RW',
    iso_alpha_3: 'RWA'
  },
  {
    code: '652',
    title: 'СЕН-БАРТЕЛЕМИ',
    full_title: '',
    iso_alpha_2: 'BL',
    iso_alpha_3: 'BLM'
  },
  {
    code: '654',
    title: 'СВЯТАЯ ЕЛЕНА, ОСТРОВ ВОЗНЕСЕНИЯ, ТРИСТАН-ДА-КУНЬЯ',
    full_title: '',
    iso_alpha_2: 'SH',
    iso_alpha_3: 'SHN'
  },
  {
    code: '659',
    title: 'СЕНТ-КИТС И НЕВИС',
    full_title: '',
    iso_alpha_2: 'KN',
    iso_alpha_3: 'KNA'
  },
  {
    code: '660',
    title: 'АНГИЛЬЯ',
    full_title: '',
    iso_alpha_2: 'AI',
    iso_alpha_3: 'AIA'
  },
  {
    code: '662',
    title: 'СЕНТ-ЛЮСИЯ',
    full_title: '',
    iso_alpha_2: 'LC',
    iso_alpha_3: 'LCA'
  },
  {
    code: '663',
    title: 'СЕН-МАРТЕН (французская часть)\n',
    full_title: '',
    iso_alpha_2: 'MF',
    iso_alpha_3: 'MAF'
  },
  {
    code: '666',
    title: 'СЕН-ПЬЕР И МИКЕЛОН',
    full_title: '',
    iso_alpha_2: 'PM',
    iso_alpha_3: 'SPM'
  },
  {
    code: '670',
    title: 'СЕНТ-ВИНСЕНТ И ГРЕНАДИНЫ',
    full_title: '',
    iso_alpha_2: 'VC',
    iso_alpha_3: 'VCT'
  },
  {
    code: '674',
    title: 'САН-МАРИНО',
    full_title: 'Республика Сан-Марино',
    iso_alpha_2: 'SM',
    iso_alpha_3: 'SMR'
  },
  {
    code: '678',
    title: 'САН-ТОМЕ И ПРИНСИПИ',
    full_title: 'Демократическая Республика Сан-Томе и Принсипи',
    iso_alpha_2: 'ST',
    iso_alpha_3: 'STP'
  },
  {
    code: '682',
    title: 'САУДОВСКАЯ АРАВИЯ',
    full_title: 'Королевство Саудовская Аравия',
    iso_alpha_2: 'SA',
    iso_alpha_3: 'SAU'
  },
  {
    code: '686',
    title: 'СЕНЕГАЛ',
    full_title: 'Республика Сенегал',
    iso_alpha_2: 'SN',
    iso_alpha_3: 'SEN'
  },
  {
    code: '688',
    title: 'СЕРБИЯ',
    full_title: 'Республика Сербия',
    iso_alpha_2: 'RS',
    iso_alpha_3: 'SRB'
  },
  {
    code: '690',
    title: 'СЕЙШЕЛЫ',
    full_title: 'Республика Сейшелы',
    iso_alpha_2: 'SC',
    iso_alpha_3: 'SYC'
  },
  {
    code: '694',
    title: 'СЬЕРРА-ЛЕОНЕ',
    full_title: 'Республика Сьерра-Леоне',
    iso_alpha_2: 'SL',
    iso_alpha_3: 'SLE'
  },
  {
    code: '702',
    title: 'СИНГАПУР',
    full_title: 'Республика Сингапур',
    iso_alpha_2: 'SG',
    iso_alpha_3: 'SGP'
  },
  {
    code: '703',
    title: 'СЛОВАКИЯ',
    full_title: 'Словацкая Республика',
    iso_alpha_2: 'SK',
    iso_alpha_3: 'SVK'
  },
  {
    code: '704',
    title: 'ВЬЕТНАМ',
    full_title: 'Социалистическая Республика Вьетнам',
    iso_alpha_2: 'VN',
    iso_alpha_3: 'VNM'
  },
  {
    code: '705',
    title: 'СЛОВЕНИЯ',
    full_title: 'Республика Словения',
    iso_alpha_2: 'SI',
    iso_alpha_3: 'SVN'
  },
  {
    code: '706',
    title: 'СОМАЛИ',
    full_title: 'Федеративная Республика Сомали',
    iso_alpha_2: 'SO',
    iso_alpha_3: 'SOM'
  },
  {
    code: '710',
    title: 'ЮЖНАЯ АФРИКА',
    full_title: 'Южно-Африканская Республика',
    iso_alpha_2: 'ZA',
    iso_alpha_3: 'ZAF'
  },
  {
    code: '716',
    title: 'ЗИМБАБВЕ',
    full_title: 'Республика Зимбабве',
    iso_alpha_2: 'ZW',
    iso_alpha_3: 'ZWE'
  },
  {
    code: '724',
    title: 'ИСПАНИЯ',
    full_title: 'Королевство Испания',
    iso_alpha_2: 'ES',
    iso_alpha_3: 'ESP'
  },
  {
    code: '728',
    title: 'ЮЖНЫЙ СУДАН',
    full_title: 'Республика Южный Судан',
    iso_alpha_2: 'SS',
    iso_alpha_3: 'SSD'
  },
  {
    code: '729',
    title: 'СУДАН',
    full_title: 'Республика Судан',
    iso_alpha_2: 'SD',
    iso_alpha_3: 'SDN'
  },
  {
    code: '732',
    title: 'ЗАПАДНАЯ САХАРА',
    full_title: '',
    iso_alpha_2: 'EH',
    iso_alpha_3: 'ESH'
  },
  {
    code: '740',
    title: 'СУРИНАМ',
    full_title: 'Республика Суринам',
    iso_alpha_2: 'SR',
    iso_alpha_3: 'SUR'
  },
  {
    code: '744',
    title: 'ШПИЦБЕРГЕН И ЯН МАЙЕН',
    full_title: '',
    iso_alpha_2: 'SJ',
    iso_alpha_3: 'SJM'
  },
  {
    code: '748',
    title: 'ЭСВАТИНИ',
    full_title: 'Королевство Эсватини',
    iso_alpha_2: 'SZ',
    iso_alpha_3: 'SWZ'
  },
  {
    code: '752',
    title: 'ШВЕЦИЯ',
    full_title: 'Королевство Швеция',
    iso_alpha_2: 'SE',
    iso_alpha_3: 'SWE'
  },
  {
    code: '756',
    title: 'ШВЕЙЦАРИЯ',
    full_title: 'Швейцарская Конфедерация',
    iso_alpha_2: 'CH',
    iso_alpha_3: 'CHE'
  },
  {
    code: '760',
    title: 'СИРИЙСКАЯ АРАБСКАЯ РЕСПУБЛИКА',
    full_title: '',
    iso_alpha_2: 'SY',
    iso_alpha_3: 'SYR'
  },
  {
    code: '762',
    title: 'ТАДЖИКИСТАН',
    full_title: 'Республика Таджикистан',
    iso_alpha_2: 'TJ',
    iso_alpha_3: 'TJK'
  },
  {
    code: '764',
    title: 'ТАИЛАНД',
    full_title: 'Королевство Таиланд',
    iso_alpha_2: 'TH',
    iso_alpha_3: 'THA'
  },
  {
    code: '768',
    title: 'ТОГО',
    full_title: 'Тоголезская Республика',
    iso_alpha_2: 'TG',
    iso_alpha_3: 'TGO'
  },
  {
    code: '772',
    title: 'ТОКЕЛАУ',
    full_title: '',
    iso_alpha_2: 'TK',
    iso_alpha_3: 'TKL'
  },
  {
    code: '776',
    title: 'ТОНГА',
    full_title: 'Королевство Тонга',
    iso_alpha_2: 'TO',
    iso_alpha_3: 'TON'
  },
  {
    code: '780',
    title: 'ТРИНИДАД И ТОБАГО',
    full_title: 'Республика Тринидад и Тобаго',
    iso_alpha_2: 'TT',
    iso_alpha_3: 'TTO'
  },
  {
    code: '784',
    title: 'ОБЪЕДИНЕННЫЕ АРАБСКИЕ ЭМИРАТЫ',
    full_title: '',
    iso_alpha_2: 'AE',
    iso_alpha_3: 'ARE'
  },
  {
    code: '788',
    title: 'ТУНИС',
    full_title: 'Тунисская Республика',
    iso_alpha_2: 'TN',
    iso_alpha_3: 'TUN'
  },
  {
    code: '792',
    title: 'ТУРЦИЯ',
    full_title: 'Турецкая Республика',
    iso_alpha_2: 'TR',
    iso_alpha_3: 'TUR'
  },
  {
    code: '795',
    title: 'ТУРКМЕНИСТАН',
    full_title: 'Туркменистан',
    iso_alpha_2: 'TM',
    iso_alpha_3: 'TKM'
  },
  {
    code: '796',
    title: 'ОСТРОВА ТЕРКС И КАЙКОС',
    full_title: '',
    iso_alpha_2: 'TC',
    iso_alpha_3: 'TCA'
  },
  {
    code: '798',
    title: 'ТУВАЛУ',
    full_title: '',
    iso_alpha_2: 'TV',
    iso_alpha_3: 'TUV'
  },
  {
    code: '800',
    title: 'УГАНДА',
    full_title: 'Республика Уганда',
    iso_alpha_2: 'UG',
    iso_alpha_3: 'UGA'
  },
  {
    code: '804',
    title: 'УКРАИНА',
    full_title: '',
    iso_alpha_2: 'UA',
    iso_alpha_3: 'UKR'
  },
  {
    code: '807',
    title: 'СЕВЕРНАЯ МАКЕДОНИЯ',
    full_title: 'Республика Северная Македония',
    iso_alpha_2: 'MK',
    iso_alpha_3: 'MKD'
  },
  {
    code: '818',
    title: 'ЕГИПЕТ',
    full_title: 'Арабская Республика Египет',
    iso_alpha_2: 'EG',
    iso_alpha_3: 'EGY'
  },
  {
    code: '826',
    title: 'СОЕДИНЕННОЕ КОРОЛЕВСТВО',
    full_title: 'Соединенное Королевство Великобритании и Северной Ирландии',
    iso_alpha_2: 'GB',
    iso_alpha_3: 'GBR'
  },
  {
    code: '831',
    title: 'ГЕРНСИ',
    full_title: '',
    iso_alpha_2: 'GG',
    iso_alpha_3: 'GGY'
  },
  {
    code: '832',
    title: 'ДЖЕРСИ',
    full_title: '',
    iso_alpha_2: 'JE',
    iso_alpha_3: 'JEY'
  },
  {
    code: '833',
    title: 'ОСТРОВ МЭН',
    full_title: '',
    iso_alpha_2: 'IM',
    iso_alpha_3: 'IMN'
  },
  {
    code: '834',
    title: 'ТАНЗАНИЯ, ОБЪЕДИНЕННАЯ РЕСПУБЛИКА',
    full_title: 'Объединенная Республика Танзания',
    iso_alpha_2: 'TZ',
    iso_alpha_3: 'TZA'
  },
  {
    code: '840',
    title: 'СОЕДИНЕННЫЕ ШТАТЫ',
    full_title: 'Соединенные Штаты Америки',
    iso_alpha_2: 'US',
    iso_alpha_3: 'USA'
  },
  {
    code: '850',
    title: 'ВИРГИНСКИЕ ОСТРОВА (США)',
    full_title: 'Виргинские острова Соединенных Штатов',
    iso_alpha_2: 'VI',
    iso_alpha_3: 'VIR'
  },
  {
    code: '854',
    title: 'БУРКИНА-ФАСО',
    full_title: '',
    iso_alpha_2: 'BF',
    iso_alpha_3: 'BFA'
  },
  {
    code: '858',
    title: 'УРУГВАЙ',
    full_title: 'Восточная Республика Уругвай',
    iso_alpha_2: 'UY',
    iso_alpha_3: 'URY'
  },
  {
    code: '860',
    title: 'УЗБЕКИСТАН',
    full_title: 'Республика Узбекистан',
    iso_alpha_2: 'UZ',
    iso_alpha_3: 'UZB'
  },
  {
    code: '862',
    title: 'ВЕНЕСУЭЛА (БОЛИВАРИАНСКАЯ РЕСПУБЛИКА)',
    full_title: 'Боливарианская Республика Венесуэла',
    iso_alpha_2: 'VE',
    iso_alpha_3: 'VEN'
  },
  {
    code: '876',
    title: 'УОЛЛИС И ФУТУНА',
    full_title: '',
    iso_alpha_2: 'WF',
    iso_alpha_3: 'WLF'
  },
  {
    code: '882',
    title: 'САМОА',
    full_title: 'Независимое Государство Самоа',
    iso_alpha_2: 'WS',
    iso_alpha_3: 'WSM'
  },
  {
    code: '887',
    title: 'ЙЕМЕН',
    full_title: 'Йеменская Республика',
    iso_alpha_2: 'YE',
    iso_alpha_3: 'YEM'
  },
  {
    code: '894',
    title: 'ЗАМБИЯ',
    full_title: 'Республика Замбия',
    iso_alpha_2: 'ZM',
    iso_alpha_3: 'ZMB'
  },
  {
    code: '895',
    title: 'АБХАЗИЯ',
    full_title: 'Республика Абхазия',
    iso_alpha_2: 'AB',
    iso_alpha_3: 'ABH'
  },
  {
    code: '896',
    title: 'ЮЖНАЯ ОСЕТИЯ',
    full_title: 'Республика Южная Осетия',
    iso_alpha_2: 'OS',
    iso_alpha_3: 'OST'
  }
]

export function getCountry (code) {
  for (const country of COUNTRIES) {
    if (country.code === code) {
      return country
    }
  }
}
